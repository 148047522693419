<template>
    <div>
        <ts-loading-banner :loading="fetch_data">
            <div class="tw-flex tw-space-x-3 tw-items-center">
                <a-date-picker
                    v-model="model.date_transaction"
                    format="DD-MM-YYYY"
                    valueFormat="DD-MM-YYYY"
                    :allowClear="false"
                    @change="fetchData()"
                />
								<a-select
											show-search
											style="width:250px"
											:default-active-first-option="false"
											:show-arrow="false"
											:filter-option="false"
											:not-found-content="null"
											:placeholder="
													$t('shopCompensation.typeSearch')
											"
											@change="onShopChange"
											@search="onShopSearch"
											:loading="fetch_shop"
									>
											<a-select-option
													v-for="s in shopProfile"
													:key="s.shop_id"
													:value="s.shop_id"
											>
													{{ s.shop_code + `| ${s.shop_name}` }}
											</a-select-option>
									</a-select>		
                <a-button type="primary" @click.prevent="onClearance">{{
                    $t("shopCompensation.clearance")
                }}</a-button>
                <a-button
                    @click="$router.push({ name: 'shop-compensation' })"
                    >{{ $t("cancel") }}</a-button
                >
            </div>
            <div class="tw-mt-3">
                <table class="tw-w-full">
                    <thead>
                        <tr class="tw-bg-blue-400 tw-text-white">
                            <th
                                class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                v-for="(c, index) in columns"
                                :key="index"
                            >
                                {{ c.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(record, index) in resources">
                            <tr :key="index">
                                <td
                                    class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border-b expandable-row"
                                >
                                    <div
                                        class="expandable-row__icon"
                                        :class="{
                                            'expandable-row__expanded':
                                                expandableItems[index]
                                        }"
                                        @click="toggleExpandedRow(index)"
                                    >
                                        <i
                                            class="fa fa-caret-down"
                                            v-if="expandableItems[index]"
                                        />
                                        <i class="fa fa-caret-right" v-else />
                                    </div>
                                </td>
                                <td class="tw-px-2 tw-py-1 tw-border-b">
                                    {{ index + 1 }}
                                </td>
                                <td class="tw-px-2 tw-py-1 tw-border-b">
                                    {{ record.transaction_date }}
                                </td>
                                <td class="tw-px-2 tw-py-1 tw-border-b">
                                    {{ record.shop_name }}
                                </td>
                                <td
                                    class="tw-text-right tw-px-2 tw-py-1 tw-border-b"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="record.cod_amount"
                                    />
                                </td>
                                <td
                                    class="tw-text-right tw-px-2 tw-py-1 tw-border-b"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="record.total_fee"
                                    />
                                </td>
                                <td
                                    class="tw-text-right tw-px-2 tw-py-1 tw-border-b tw-font-semibold tw-text-blue-500"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="record.amount_tobe_clear"
                                    />
                                </td>
                                <td
                                    class="tw-text-right tw-px-2 tw-py-1 tw-border-b"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="record.cod_amount_usd_cleared"
                                    />
                                </td>
                                <td
                                    class="tw-text-right tw-px-2 tw-py-1 tw-border-b"
                                >
                                    <ts-accounting-format
                                        currency="KHR"
                                        :digit="0"
                                        :value="record.cod_amount_khr_cleared"
                                    />
                                </td>
                                <td
                                    class="tw-text-right tw-px-2 tw-py-1 tw-border-b"
                                >
                                    <ts-accounting-format
                                        currency="KHR"
                                        :digit="0"
                                        :value="record.fx_rate"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="expandableItems[index]"
                                :key="'_' + index"
                            >
                                <td
                                    :colspan="columns.length"
                                    class="tw-px-3"
                                >
                                    <Details :record="record.details" />
                                    <div class="tw-w-full tw-h-4"></div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot>
                        <tr class="tw-text-red-600">
                            <td
                                colspan="4"
                                class="tw-text-right tw-uppercase tw-font-semibold tw-py-1 tw-px-2 tw-border-t-2 tw-border-blue-400"
                            >
                                {{ $t("shopCompensation.total") }} :
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-border-t-2 tw-border-blue-400 tw-px-2 tw-py-1"
                            >
                                <ts-accounting-format
                                    style="border:none"
                                    :digit="2"
                                    :value="sumTotal('cod_amount')"
                                />
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-border-t-2 tw-border-blue-400 tw-px-2 tw-py-1"
                            >
                                <ts-accounting-format
                                    style="border:none"
                                    :digit="2"
                                    :value="sumTotal('total_fee')"
                                />
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-border-t-2 tw-border-blue-400 tw-px-2 tw-py-1"
                            >
                                <ts-accounting-format
                                    style="border:none"
                                    :digit="2"
                                    :value="sumTotal('amount_tobe_clear')"
                                />
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-border-t-2 tw-border-blue-400 tw-px-2 tw-py-1"
                            >
                                <ts-accounting-format
                                    style="border:none"
                                    :digit="2"
                                    :value="sumTotal('cod_amount_usd_cleared')"
                                />
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-border-t-2 tw-border-blue-400 tw-px-2 tw-py-1"
                            >
                                <ts-accounting-format
                                    style="border:none"
                                    currency="KHR"
                                    :digit="0"
                                    :value="sumTotal('cod_amount_khr_cleared')"
                                />
                            </td>
                            <td
                                class="tw-text-right tw-font-semibold tw-border-t-2 tw-border-blue-400 tw-px-2 tw-py-1"
                            ></td>
                        </tr>
                    </tfoot>
                </table>
                <div class="tw-w-56">
                    <textarea
                        class="form-control"
                        v-model="model.remark"
                        :placeholder="$t('shopCompensation.remark')"
                        rows="2"
                        cols="4"
                    ></textarea>
                </div>
            </div>
            <a-modal
                :title="$t('pdf')"
                width="50%"
                v-model="preview_pdf"
                :footer="null"
            >
                <ts-preview-pdf v-model="loading" :src="src" />
            </a-modal>
        </ts-loading-banner>
    </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { join, sumBy, debounce } from "lodash";
import Details from "./detail.vue";
export default {
    name: "formCreateShopCompensate",
    components: {
        Details
    },
    data() {
        return {
            loading: false,
            fetch_data: false,
            preview_pdf: false,
            fetch_shop: false,
            src: "",
            resources: [],
            expandableItems: [],
						shopProfile: [],
            model: {
                date_transaction: moment().format("DD-MM-YYYY"),
                shop_id: null,
                remark: ""
            }
        };
    },
    computed: {
        columns() {
            return [
                { name: "" },
                { name: this.$t("numeric") },
                { name: this.$t("shopCompensation.dateTransaction") },
                { name: this.$t("shopCompensation.shopName") },
                {
                    name: this.$t("shopCompensation.codAmount"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.totalFee"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.amountToBeClear"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.codUSD"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.codKHR"),
                    style: "text-align:right"
                },
                {
                    name: this.$t("shopCompensation.fxRate"),
                    style: "text-align:right"
                }
            ];
        },
        sumTotal: vm => column => {
            var amount = sumBy(vm.resources, column);

            return amount;
        }
    },
    methods: {
        ...mapActions("finance/shopCompensation", ["store", 'getFormViewData']),
        fetchData() {
            this.fetch_data = true;
            this.$store
                .dispatch("finance/shopCompensation/fetchToCreate", {
                    filter: {
												shop_id: this.model.shop_id,
                        is_shop_clear: 0,
                        date_between: join(
                            [
                                this.model.date_transaction,
                                this.model.date_transaction
                            ],
                            ","
                        )
                    }
                })
                .then(response => {
                    this.resources = response.data;
                    this.resources.forEach((_, index) => {
                        this.expandableItems[index] = false;
                    });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.fetch_data = false;
                });
        },
        toggleExpandedRow(index) {
            this.$set(
                this.expandableItems,
                index,
                !this.expandableItems[index]
            );
        },
        onClearance() {
            this.loading = true;
            this.preview_pdf = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                    this.src = response.url;
                    // this.$router.push({ name: "shop-compensation" });
                })
                .catch(error => {
                    this.preview_pdf = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.remark = "";
            this.model.shop_id = null;
            this.date_transaction = moment().format("DD-MM-YYYY");
            this.resources = [];
            this.expandableItems = [];
        },
				onShopChange(id){
					this.model.shop_id = id
					this.fetchData();
				},
				onShopSearch: debounce(function(value){
					this.fetch_shop = true
					this.getFormViewData({
						params: {
							shop_search: value,
							fnName: 'shop'
						}
					})
					.then(response => {
						this.shopProfile = response.data['shop']
					})
					.finally(() => this.fetch_shop = false)

				}, 500)
    }
};
</script>
