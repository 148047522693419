<template>
	<div>
		<ts-page-title
			:title="$t('shopCompensation.pageTitle')"
			:breadcrumb="[
					{ text: $t('home'), href: '/' },
					{
							text: $t('shopCompensation.pageTitle'),
							href: '/admin/finance/shop-compensations'
					},
					{
							text: $t('create'),
							active: true
					}
			]"
		/>
		<ts-panel>
			<ts-panel-wrapper>
				<Form />
			</ts-panel-wrapper>
		</ts-panel>
	</div>
</template>

<script>
import Form from './components/form-create.vue'

	export default {
		components: {
			Form,
		},
		name: 'shopCompensateCreate'
	}
</script>
