<template>
    <table class="tw-w-full">
        <thead>
            <tr class="tw-bg-blue-400 tw-text-white">
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.dateTransaction") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.serviceType") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.receiverPhone") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.receiverLocation") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.codAmount") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.packagingFee") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.extraFee") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.deliveryFee") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.taxiFee") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.totalFee") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.amountToBeClear") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.codUSD") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.codKHR") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border tw-text-right">
                    {{ $t("shopCompensation.fxRate") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.feePaidBy") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.clearDate") }}
                </th>
                <th class="tw-px-2 tw-whitespace-nowrap tw-py-1 tw-border">
                    {{ $t("shopCompensation.clearBy") }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(ep, index) in record" :key="index">
                <td
                    class="tw-whitespace-nowrap tw-py-1 tw-border tw-px-2"
                >
                    {{ ep.transaction_date }}
                </td>
                <td class="tw-text-left tw-border tw-py-1 tw-px-2">
                    {{ ep.service_type }}
                </td>
                <td class="tw-text-left tw-border tw-py-1 tw-px-2">
                    {{ ep.receiver_phone }}
                </td>
                <td class="tw-text-left tw-border tw-py-1 tw-px-2">
                    {{ ep.receiver_location }}
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										style="border:none" 
										:digit="2"
										:value="ep.cod_amount" 
									/>
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										style="border:none" 
										:digit="2"
										:value="ep.packaging_fee" 
									/>								
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										style="border:none" 
										:digit="2"
										:value="ep.extra_fee" 
									/>
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										style="border:none" 
										:digit="2"
										:value="ep.delivery_fee" 
									/>
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										currency="KHR"
										:digit="0"
										:value="ep.tax_fee" 
									/>
                </td>
                <td class="tw-text-right tw-border tw-py-1 tw-px-2">
									<ts-accounting-format
										:digit="2"
										:value="ep.total_fee" 
									/>
                </td>
                <td
                    class="tw-text-right tw-font-semibold tw-text-blue-500 tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										:digit="2"
										:value="ep.amount_tobe_clear" 
									/>
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										:digit="2"
										:value="ep.cod_amount_usd_cleared" 
									/>
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										currency="KHR"
										:digit="0"
										:value="ep.cod_amount_khr_cleared" 
									/>
                </td>
                <td
                    class="tw-text-right tw-whitespace-nowrap tw-border tw-py-1 tw-px-2"
                >
									<ts-accounting-format
										currency="KHR"
										:digit="0"
										:value="ep.fx_rate" 
									/>
                </td>
                <td class="tw-border tw-py-1 tw-px-2">
                    {{ ep.fee_paid_by }}
                </td>
                <td class="tw-whitespace-nowrap tw-border tw-py-1 tw-px-2">
                    {{ ep.shop_clear_date }}
                </td>
                <td class="tw-border tw-py-1 tw-px-2">
                    {{ ep.shop_clear_by }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
	props: ['record']
};
</script>
