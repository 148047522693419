var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('shopCompensation.pageTitle'),"breadcrumb":[
				{ text: _vm.$t('home'), href: '/' },
				{
						text: _vm.$t('shopCompensation.pageTitle'),
						href: '/admin/finance/shop-compensations'
				},
				{
						text: _vm.$t('create'),
						active: true
				}
		]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('Form')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }